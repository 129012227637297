import React, { useState, useEffect } from "react";
import Context from "./index";




const RoleProvider = (props) => {

    const [role, setRole] = useState(null);
    //const history = useNavigate();




    return (
        <Context.Provider
            value={{
                ...props,
                role,
                setRole: setRole
            }}
        >
            {props.children}
        </Context.Provider>
    );
}

export default RoleProvider;
