import { axiosInstance } from "../../CustomAxios";

export const GetProfile = async () => {
    try {
        const response = await axiosInstance.get('/admin/show');
        return response
    }
    catch (error) {
        throw error;
    }
}


export const updateProfile = async (data) => {
    try {
        const response = await axiosInstance.post('/admin/editprofile', data);
        return response
    }
    catch (error) {
        throw error;
    }
}
