import { useState } from 'react';

// Custom hook for handling localStorage
const useLocalStorage = (key, initialValue) => {
  // Get initial value from localStorage or use provided initialValue
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : initialValue | 0;

  // State to hold the current value
  const [value, setValue] = useState(initial);

  // Function to update both state and localStorage
  const setStoredValue = (newValue) => {
    setValue(newValue);
    localStorage.setItem(key, JSON?.stringify(newValue));
  };

  // Function to get the current value
  const getToken = () => {
    return value;
  };

  // Function to clear the stored value
  const clearToken = () => {
    setValue(null);
    localStorage.removeItem(key);
  };

  return { value, setStoredValue, getToken, clearToken };
};

export default useLocalStorage;
