import React, { useState, useEffect } from "react";
import Context from "./index";


const ProfileProvider = (props) => {

    const [profile, setProfile] = useState(null)
    return (
        <Context.Provider
            value={{
                ...props,
                profile,
                setProfile: setProfile
            }}
        >
            {props.children}
        </Context.Provider>
    );
}

export default ProfileProvider;
