import React, { useState, useEffect } from "react";
import Context from "./index";




const NotificationProvider = (props) => {

    const [notification, setNotification] = useState(null);
    //const history = useNavigate();




    return (
        <Context.Provider
            value={{
                ...props,
                notification,
                setNotification: setNotification
            }}
        >
            {props.children}
        </Context.Provider>
    );
}

export default NotificationProvider;