
import './App.css';
import RouterLayout from './Router';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import { SnackbarProvider } from './hooks/SnackBarHook';
import { LoadScript } from '@react-google-maps/api';
import ProfileProvider from './context/profile/profileContext';
import UserProvider from './context/user/userContext';

import { Typography } from '@mui/material';
import { getTokenF, onMessageListener } from './firebase/firebase';
import { useCallback, useEffect, useState } from 'react';
import Toast from './components/CustomFirebaseToast';
import NotificationProvider from './context/notification/notificationContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoleProvider from './context/role/roleContext';
import { GetProfile, updateProfile } from './api/profile';

const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: 3, retryDelay: 1000 } }
})

function App() {
    const navigate = useNavigate()


    const [show, setShow] = useState(false);
    const [isTokenFound, setTokenFound] = useState(false);
    const [notification, setNotification] = useState(null);
    const [data, setData] = useState(null)
    getTokenF(setTokenFound);

    // inside the jsx being returned:
    {
        isTokenFound &&
            <Typography>👍🏻</Typography>
    }
    {
        !isTokenFound &&
            <Typography>❗️</Typography>
    }

    const NaviagteToPage = useCallback((data) => {
        if (data?.link) {
            navigate('/' + data?.link)
        }

    }, [navigate, data])

    onMessageListener().then(payload => {
        // console.log({payload},'ORDERRRRRR PALODE')

        // setNotification({title: payload.notification.title, body: payload.notification.body})
        // setData(payload?.data)
        // setShow(true)


        toast(({ closeToast }) => <div style={{ display: 'flex', flexDirection: 'column' }} onClick={() => NaviagteToPage(payload)}>
            <div style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{payload?.notification?.title}</div>
            <div>{payload?.notification?.body}</div>

        </div>);

    }).catch(err => console.log('failed: ', err));



    return (
        <>
            <QueryClientProvider client={queryClient}>
                <ToastContainer />
                <ProfileProvider>
                    <RoleProvider>

                        <NotificationProvider>
                            <SnackbarProvider>
                                <RouterLayout />
                            </SnackbarProvider>
                        </NotificationProvider>

                    </RoleProvider>
                </ProfileProvider>
            </QueryClientProvider>

        </>
    );
}

export default App;
