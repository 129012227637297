export const env = "devTwo"

const URLS = {
    dev: "https://kimchiapi.diginestsolutions.in/public/api/",
    demo:'https://digisvapi.diginestsolutions.in/public/api/',
    devTwo: 'https://api.japanesekoreanmart.com/api/'
}

const IMAGE = {
    dev :'https://kimchiapi.diginestsolutions.in/public',
    demo:'https://digisvapi.diginestsolutions.in/public',
    devTwo: 'https://api.japanesekoreanmart.com/'
}
export const BASE_URL = URLS[env];
export const IMG_URL = IMAGE[env]