import { axiosInstance } from "../../CustomAxios";

export const firebaseToken = async (data) => {
    try {
        const response = await axiosInstance.post('/admin/updatetoken', data);
        return response
    }
    catch (error) {
        throw error;
    }
}