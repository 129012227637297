import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseToken } from '../api/firebasee';
import useLocalStorage from '../hooks/localStoragehook';
import { useQuery } from 'react-query';
var firebaseConfig = {
    apiKey: "AIzaSyABhT3qjUPl5e7dnSmllrGEFfL3tR4WDaY",
    authDomain: "kimchi-9e0d4.firebaseapp.com",
    projectId: "kimchi-9e0d4",
    storageBucket: "kimchi-9e0d4.appspot.com",
    messagingSenderId: "149560838671",
    appId: "1:149560838671:web:0c3b682b9c43c65ce5f17a"
};

initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenF = async (setTokenFound) => {


    return getToken(messaging, { vapidKey: 'BDLKvsAWWDWawuYl_QMeVbq5p6l482gJVNG1-fLootqabQHPU77Z24xhUTUqn5gqMiHL0WJdrgl-TDsCTOb5PyY' }).then(async (currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);

     

           if(!localStorage.getItem('firebaseToken')){
            try {
                let ftokn = { web_token: currentToken }
                await firebaseToken(ftokn);
                localStorage.setItem('firebaseToken',true)

            } catch (err) {

            }

           }

          

            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured



        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });