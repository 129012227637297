
import React from 'react';
import { Typography, Container } from '@mui/material';

const NotFound = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" style={{ marginTop: '50px' }}>
        Page Not Found
      </Typography>
      <Typography variant="body1" align="center" style={{ marginTop: '20px' }}>
        The requested page could not be found.
      </Typography>
    </Container>
  );
};

export default NotFound;