import React, { useContext, useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import { PrivateRoute } from './PrivateRoute';
import ErrorPage from './ErrorPage';
import useLocalStorage from '../hooks/localStoragehook';
import NotFound from '../screens/NotFound';
import { GetProfile } from '../api/profile';
import RoleContext from '../context/role';
import ProfileContext from '../context/profile';
import { useSnackbar } from '../hooks/SnackBarHook';


const LazyLayout = React.lazy(() => import('../screens/LoginLayout'));
const LazyLogin = React.lazy(() => import('../screens/auth/login'));
const LazyEmailRegister = React.lazy(() => import('../screens/auth/email'));
const LazyConfirmPassword = React.lazy(() => import('../screens/auth/password'));
const LazyHomeLayout = React.lazy(() => import('../screens/HomeLayout'));
const LazyCategory = React.lazy(() => import('../screens/CategoryManagement/index'));
const LazyStore = React.lazy(() => import('../screens/StoreManagement/index'));
const LazyProduct = React.lazy(() => import('../screens/ProductManagement/index'));
const LazyOrder = React.lazy(() => import('../screens/OrderManagement/index'));
const LazyCustomer = React.lazy(() => import('../screens/CustomerManagement/index'));
const LazyUser = React.lazy(() => import('../screens/UserManagement/index'));
const LazySlider = React.lazy(() => import('../screens/SliderManagement/index'));
const LazyProfile = React.lazy(() => import('../screens/Profile/index'));
const LazySettings = React.lazy(() => import('../screens/Settings/index'));
const LazyNotification = React.lazy(() => import('../screens/Notification/index'))



const RouterLayout = () => {
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState(false)
    const { role, setRole } = useContext(RoleContext);
    const { setProfile } = React.useContext(ProfileContext);
    const { value, setStoredValue, getToken, clearToken } = useLocalStorage('user', '');

    console.log({value})

    // const [roles, setroles] = useState('');


    // const { value: role, setStoredValue: storerole } = useLocalStorage('role', '');


    // useEffect(() => {
    //     if (role) {
    //         setroles(role)
    //     }
    // }, [role])




    const roleFunction = async () => {
        try {
            setLoading(true)
            const resp = await GetProfile();
            setRole(resp?.data?.data?.role);
            setProfile(resp?.data?.data);
        } catch (err) {
            if((value !== 0 && err?.message === 'Unauthenticated.')){
                setProfile(null);
                localStorage.clear();
                showSnackbar(err?.message, 'error');
                navigate('/login', { replace: true });
            }
        } finally {
            setLoading(false)
        }

    }



    useEffect(() => {
        roleFunction()
        return () => {
            roleFunction(); // Assuming there is a cleanup function if needed
          };
    }, [])

    if (!role && loading) {
       return <div>Loading...</div>
    }

    return (
        <Routes>
            <Route path="/login" element={<React.Suspense fallback='loading...'><LazyLayout /></React.Suspense>} >
                <Route index element={<React.Suspense fallback='loading...'><LazyLogin /></React.Suspense>} />
            </Route>
            <Route path="/register" element={<React.Suspense fallback='loading...'><LazyLayout /></React.Suspense>} >
                <Route index element={<React.Suspense fallback='loading...'><LazyEmailRegister /></React.Suspense>} />
            </Route>
            <Route path="/password/:id" element={<React.Suspense fallback='loading...'><LazyLayout /></React.Suspense>} >
                <Route index element={<React.Suspense fallback='loading...'><LazyConfirmPassword /></React.Suspense>} />
            </Route>
            <Route path="/" element={<React.Suspense fallback='loading...'><PrivateRoute><LazyHomeLayout /></PrivateRoute> </React.Suspense>} errorElement={<ErrorPage />} >
                <Route index element={<React.Suspense fallback='loading...'> {role === "admin" ? <LazyCategory /> : <LazyOrder />} </React.Suspense>} />
                <Route path='*' element={<NotFound />} />
                {role === 'admin' ?
                    <>
                        <Route index path='/storeManagement' element={<React.Suspense fallback='loading...'> <LazyStore /></React.Suspense>} />
                        <Route index path='/productManagement' element={<React.Suspense fallback='loading...'> <LazyProduct /></React.Suspense>} />
                        <Route index path='/orderManagement' element={<React.Suspense fallback='loading...'> <LazyOrder /></React.Suspense>} />
                        <Route index path='/customerManagement' element={<React.Suspense fallback='loading...'> <LazyCustomer /></React.Suspense>} />
                        <Route index path='/userManagement' element={<React.Suspense fallback='loading...'> <LazyUser /></React.Suspense>} />
                        <Route index path='/sliderManagement' element={<React.Suspense fallback='loading...'> <LazySlider /></React.Suspense>} />
                        <Route index path='/profile' element={<React.Suspense fallback='loading...'> <LazyProfile /></React.Suspense>} />
                        <Route index path='/settings' element={<React.Suspense fallback='loading...'> <LazySettings /></React.Suspense>} />
                        <Route index path='/notification' element={<React.Suspense fallback='loading...'> <LazyNotification /></React.Suspense>} />  </> : <>
                        <Route index path='/orderManagement' element={<React.Suspense fallback='loading...'> <LazyOrder /></React.Suspense>} />
                        <Route index path='/notification' element={<React.Suspense fallback='loading...'> <LazyNotification /></React.Suspense>} /> </>}

            </Route>
        </Routes>
    )
}

export default RouterLayout